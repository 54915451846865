.at .content-wrapper {
  min-height: calc(100vh - 50px);
}

.at .login-box {
  margin-top: 0px;
  padding-top: 0px;
}

.at .login-box-body {
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.at .filters .input-group .input-group-addon {
  border-radius: 0 100em 100em 0;
  border-color: #38a3f2;
  background-color: #38a3f2;
  color: white;
  right: 0;
  position: absolute;
  height: 100%;
  width: 37px;
  z-index: 30;
  float: right;
  align-items: center;
  display: flex;
}

.at .filters .input-group .input-group-addon .glyphicon {
  align-items: center;
  display: flex;
  justify-content: center;
}

.at .filters .input-group {
  width: 95%;
}

.at .form-control {
  width: 100%;
  border-radius: 2em !important;
  /* non ho trovato da nessuna parte il codice css che lo sovrascrive -> !important */
  border-color: #6ec5f6;
}

.at textarea.form-control {
  border-radius: 1em !important;
}

.at .filters {
  width: 35%;
}

.at .flex {
  display: flex;
  align-items: center;
  justify-content: start;
}

.at .btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100em;
  color: white;
}

.at .btn-secondary {
  background: #2b6db4;
  border: none;
  color: white;
}

.at .btn-primary {
  background: #0099ff;
  border: none;
  color: white;
}

.at .btn-primary:hover {
  background: #2b6db4;
  border: none;
  color: white;
}

.at .box {
  position: relative;
  border-radius: 3px 0;
  background: #ffffff;
  border: none;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: none;
}

.at table {
  border: none;
}

.at .table-header {
  background-color: #05193f !important;
  color: white !important;
}

.at .skin-blue .sidebar-menu > li.active > a {
  border-left-color: transparent;
}

.at .skin-blue .main-header .navbar {
  background: #1a4572;
}

.at a {
  color: #71c1f5;
  text-decoration: underline;
}

.at a:hover {
  color: #71c1f5;
  text-decoration: underline;
}

.at a.btn,
.at a.btn:hover {
  text-decoration: none;
}

.at a.logo {
  background-color: #2ba2f2 !important;
  text-decoration: none;
}

.at a.logo:hover {
  text-decoration: none;
}

.at .logo-mini {
  margin-left: -2px !important;
}

.at .margin-left-10 {
  margin-left: 10px;
}

.at .sidebar-toggle {
  border: none;
}

.at .login-box a,
.at .login-box button span,
.sidebar-menu span {
  text-decoration: none !important;
  text-decoration-line: none !important;
  text-align: center;
  font-weight: bold;
  width: 100%;
  color: white;
}

.Dropzone {
  font-weight: bold;
}

td span.notif-status-error {
  text-transform: capitalize;
  color: red;
}

td span.notif-status-sent {
  text-transform: capitalize;
  color: green;
}

.login-logo {
  padding-top: 28px;
  padding-bottom: 28px;
  margin-bottom: 28px;
  background: #00409a;
  background-clip: padding-box;
  height: 230px;
  width: 247px;
  margin-left: auto;
  background-image: url('imgAirtubeLogo@2x.png');
  background-repeat: no-repeat;
  background-size: auto 150px;
  background-position-x: center;
  background-position-y: 50px;
  margin-right: auto;
  border-radius: 0 0 15% 15%;
}

.login-page .version {
  color: #888;
  position: absolute;
  bottom: 1em;
  right: 1em;
}

select,
select.form-control,
option {
  border-radius: 1px !important;
  border-bottom-left-radius: 1px !important;
}

select.input-xs {
  line-height: 20px;
  height: 20px;
}

select.form-control.input-inline {
  display: inline-block;
  width: inherit;
  margin-left: 0.4em;
  margin-right: 0.4em;
}

h1 .title-tag {
  font-size: 0.5em;
  padding-left: 1em;
}

/* ------------- LOADERS ------------ */
/* https://loading.io/css/ */

/* <div class="lds-dual-ring"></div> */
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #66f;
  border-color: #66f transparent #66f transparent;
  animation: lds-dual-ring 16s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
    border-top-color: hsl(0, 100%, 50%);
    border-bottom-color: hsl(180, 100%, 50%);
  }

  50% {
    border-top-color: hsl(180, 100%, 50%);
    border-bottom-color: hsl(0, 100%, 50%);
  }

  100% {
    transform: rotate(2880deg);
    border-top-color: hsl(360, 100%, 50%);
    border-bottom-color: hsl(-180, 100%, 50%);
  }
}

/* <div class="lds-ring"><div></div><div></div><div></div><div></div></div> */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fcf;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fcf transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*** full page loader **/

.full-page-loader {
  z-index: 1500;
  background-color: #fffa;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.full-loader {
  z-index: 1500;
  background-color: #fffa;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.lds-dual-ring {
  position: absolute;
  margin: -32px 0 0 -32px;
  top: 50%;
  left: 50%;
}

/* -------------  ------------ */

.page-nav-links {
  float: right;
  margin-right: 2em;
}

.page-nav-links button,
.page-nav-links a {
  margin-left: 0.8em;
  display: block;
}

.project-detail {
}

.project-detail h1 {
  margin-top: 0;
}

.summary label {
  margin-left: 1em;
  margin-top: 0.3em;
}

.project-detail .box h3 {
  margin: 0;
}

.project-detail .box h4 {
  margin: 0;
  font-style: italic;
}

.project-detail .box h5 {
  margin: 0;
}

.project-detail h2.structure {
  margin-top: 1em;
  margin-bottom: 1em;
}

.project-detail .structure-data,
.project-detail .project-data {
  border-top: 1px solid #ccc;
  padding-bottom: 1em;
  margin-bottom: 2em;
}

.project-detail .modified label:before {
  content: '\f02b';
  margin-right: 0.5em;
  color: #d58512;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.block {
  display: block;
}

.inline-warning {
  padding: 2px 4px;
  margin-right: 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
  border: 1px solid #c7254e;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  -top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  _font-family: Helvetica, sans-serif;
  _font-weight: 300;
  _font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  color: #000;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.button-container .btn {
  margin-right: 1em;
}

/******* filters **********/

.filter-row {
  margin-top: 1.5em;
}

.filter-cell {
  margin-top: 1.5em;
}

/******** multiselect **********/

.multiselect .box-footer {
  height: 10em;
  overflow: scroll;
}

.multiselect h4 {
  background-color: #00a7d0;
  color: #fff;
  font-size: 1em;
  margin: 0;
  padding: 0.6em;
}

.multiselect li {
  padding: 0;
  position: relative;
}

.multiselect li input[type='checkbox'] {
  position: absolute;
  left: 0.4em;
  top: 0.1em;
}

.multiselect li input[type='checkbox']:checked ~ label {
  background-color: #f4f4ff;
}

.multiselect label {
  height: 1.9em;
  display: block;
  padding: 0.3em 2.5em 0.3em 2em;
  margin: 0;
  font-weight: normal;
  font-size: 0.8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiselect li .badge {
  position: absolute;
  right: 0.2em;
  top: 0.3em;
  font-size: 0.7em;
  font-weight: normal;
  z-index: 10;
}

.multiselect li.child-option {
  padding-left: 1em;
}

.multiselect li.child-option input[type='checkbox'] {
  padding-left: 1em;
  left: 1.4em;
}

.multiselect.withCheckboxes h4 {
  font-weight: bold;
}

.multiselect.withCheckboxes h4:after {
  content: '\f0b0';
  /* this is your text. You can also use UTF-8 character codes as I do here */
  font-family: FontAwesome;
  left: -5px;
  margin-left: 0.4em;
  top: 0;
}

.mytextwithicon:before {
  content: '\25AE';
  /* this is your text. You can also use UTF-8 character codes as I do here */
  font-family: FontAwesome;
  left: -5px;
  position: absolute;

  top: 0;
}

/*Notifications */
.notification-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
}

.notification-header .padding-top {
  padding-top: 1rem;
}

.btn-notification-action,
.btn-notification-action span {
  padding: 0 0.2em;
  color: black;
  height: auto;
  min-width: auto;
}
.btn-notification-action {
  border: none;
}

.text-align-center {
  text-align: center;
}

.break {
  flex-basis: 100%;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.d-flex {
  display: flex;
}

.j-c-c {
  justify-content: center;
}

.j-c-b {
  justify-content: space-between;
}

.j-c-e {
  justify-content: space-evenly;
}

.m-0 {
  margin: 0 !important;
}

/* .notification-text {
  min-width: 45rem !important;
  border-radius: 5px !important;
  border-color: #6ec5f6;
}
.notification-date {
  border-radius: 5px !important;
  border-color: #6ec5f6;
}
.notification-lang {
  border-radius: 5px !important;
  border-color: #6ec5f6;
  height: 26px;
}
.notification-creator {
  height: 30rem;
}
.notification-create-card {
  border-top: 2px solid #6ec5f6;
  background: #fff;
  border-radius: 2px;
  padding: 0 2rem 1rem 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.notifications-buttons {
  max-width: 45rem;
  padding-top: 2rem;
}
.notifications-container .box-header {
  padding: 0 !important;
  margin: 0 !important;
} */
.noti-menu {
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
}

.noti-menu > div {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.noti-select {
  min-width: 12rem;
}

.noti-menu-button {
  margin-left: 2rem !important;
  margin-right: 1rem !important;
  min-width: 14rem;
}

.noti-create-button {
  margin-left: 1rem !important;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.w-100 {
  width: 100%;
}

.padding-bottom {
  padding-bottom: 1rem;
}

.card-shadow {
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.bg-white {
  background: white;
}

.import-box {
  /*height: 12rem;*/
}

.c-black {
  color: black !important;
}

.c-gray {
  color: grey !important;
}

.c-orange {
  color: #fe961d !important;
}

/* Compressor Detail */

.project-data > .box > .box-header {
  background: #001a3f;
  color: white;
}

.project-data .box .box-body .box-header {
  color: black;
  font-weight: bold;
  padding: 0;
}

.project-data .box .box-body .box-body .box-header {
  text-decoration: underline;
}

.project-data .box-body .box-body {
  padding: 5px 0;
}

.project-data > .box .box {
  margin: 0;
}

/* Homepage */
.homepage a {
  background: #2ba2f2;
  font-size: 1.7rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
  padding: 3.5rem 2rem;
  width: 27rem;
  text-align: center;
  border-radius: 2rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.homepage a.secondary {
  background: #979797;
}

.homepage {
  max-width: 73rem;
  padding: 2rem 4rem;
}

.homepage .spacer {
  width: 27rem;
}

/* menu */
.sidebar-menu a {
  text-decoration: none;
  font-weight: 300;
}

.sidebar-menu li:nth-child(even) a {
  background: #222d32 !important;
}

:not(.sidebar-collapse) .sidebar-menu a:hover {
  text-align: left !important;
  text-decoration: underline !important;
}

/* users */
.btn-user-action,
.btn-user-action span {
  padding: 0 0.3rem 0 0.3rem;
  color: black;
  min-width: 1rem;
  height: auto;
}

.users-search {
  min-width: 30rem;
}

.users-select {
  height: 2.6rem;
}

.user-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
}

.user-header button span {
  top: 0px !important;
  width: 1.5rem;
}

.user-header button b {
  margin-right: 1rem;
  margin-left: 1rem;
}

.user-header button {
  min-width: 11rem;
}

.users-buttons {
  padding: 2rem 0 1rem 0;
}

.users-buttons button {
  margin-right: 3rem;
}

.users-container .box-body {
  min-height: 55rem;
}

.pagination a:hover {
  width: auto;
}

.pagination li a {
  background-color: #2ba2f2;
  color: white;
  font-weight: bold;
  user-select: none;
}

.pagination li:first-child a {
  background-color: #2ba2f2;
  color: white;
  border-radius: 3rem 0 0 3rem;
}

.pagination li:last-child a {
  background-color: #2ba2f2;
  color: white;
  border-radius: 0 3rem 3rem 0;
}

.pagination li a.disabled {
  background-color: #979797;
  color: black;
}

/* Privacy policies */
.btn-privacy-action {
  padding: 0;
  height: auto;
}

.btn-privacy-action span {
  color: black !important;
}

tr.highlight {
  background: #95caee !important;
}

tr.highlight:hover {
  background: #b8d5e9 !important;
}

.privacy-menu {
  min-height: 4em;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
.privacy-menu > p {
  padding: 1em 1em 0 1em;
}

.privacy-form-transition-enter {
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s cubic-bezier(0.31, 0.92, 0.76, 1);
  transition-delay: 0.2s;
}

.privacy-form-transition-enter.privacy-form-transition-enter-active {
  opacity: 1;
  visibility: visible;
}

.privacy-form-transition-leave {
  opacity: 1;
  visibility: visible;
  transition: all 0.15s cubic-bezier(0.31, 0.92, 0.76, 1);
}

.privacy-form-transition-leave.privacy-form-transition-leave-active {
  opacity: 0;
  visibility: hidden;
}

.privacy-table-transition-enter {
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s cubic-bezier(0.31, 0.92, 0.76, 1);
  transition-delay: 0.2s;
}

.privacy-table-transition-enter.privacy-table-transition-enter-active {
  opacity: 1;
  visibility: visible;
}

.privacy-table-transition-leave {
  opacity: 1;
  visibility: visible;
  transition: all 0.2s cubic-bezier(0.31, 0.92, 0.76, 1);
}

.privacy-table-transition-leave.privacy-table-transition-leave-active {
  opacity: 0;
  visibility: hidden;
}
.absolute-privacy-table\&form-container {
  position: relative;
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
.absolute-privacy-table\&form-container > span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
/* END PRIVACY */

.at button:disabled {
  background-color: transparent;
  cursor: not-allowed;
}

.at button:disabled > span {
  color: #ddd;
}

.notification-form-transition-enter {
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s cubic-bezier(0.31, 0.92, 0.76, 1);
  transition-delay: 0.2s;
}

.notification-form-transition-enter.notification-form-transition-enter-active {
  opacity: 1;
  visibility: visible;
}

.notification-form-transition-leave {
  opacity: 1;
  visibility: visible;
  transition: all 0.15s cubic-bezier(0.31, 0.92, 0.76, 1);
}

.notification-form-transition-leave.notification-form-transition-leave-active {
  opacity: 0;
  visibility: hidden;
}

.notification-table-transition-enter {
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s cubic-bezier(0.31, 0.92, 0.76, 1);
  transition-delay: 0.2s;
}

.notification-table-transition-enter.notification-table-transition-enter-active {
  opacity: 1;
  visibility: visible;
}

.notification-table-transition-leave {
  opacity: 1;
  visibility: visible;
  transition: all 0.2s cubic-bezier(0.31, 0.92, 0.76, 1);
}

.notification-table-transition-leave.notification-table-transition-leave-active {
  opacity: 0;
  visibility: hidden;
}

.notification-action {
  position: absolute;
}

.notification-tooltip {
  position: relative;
  display: inline-block;
}

.notification-tooltip .notification-tooltip-text {
  visibility: hidden;
  opacity: 0;
  min-width: 100px;
  min-height: 2em;
  background-color: #444;
  color: white;
  text-align: center;
  line-height: 1.2em;
  word-wrap: unset;
  vertical-align: middle;
  border-radius: 0.4em;
  /* border-bottom-right-radius: 0; */
  padding: 0.5em;

  position: absolute;
  z-index: 1080;

  top: -100%;
  right: 100%;

  transition: 0.2s cubic-bezier(0.31, 0.92, 0.76, 1);
}

.notification-tooltip:hover .notification-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.absolute-table\&form-container {
  position: relative;
  height: calc(100vh - 250px);
  overflow-y: scroll;
}

.absolute-table\&form-container > span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.absolute-error-outer-container {
  position: relative;
  height: 7.5em;
  overflow-y: hidden;
}

.absolute-error-outer-container > .absolute-error-inner-container {
  position: absolute;
  max-width: 600px;
  height: 7.5em;
  overflow-y: hidden;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: #d22;
  color: white;
  font-size: 1em;
  z-index: 10000;

  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.absolute-error-outer-container
  > .absolute-error-inner-container
  > span.main-icon {
  font-size: 2em;
}

.absolute-error-outer-container
  > .absolute-error-inner-container
  > button.exit-icon {
  align-self: flex-start;
  margin: -10px -10px 0 0;
  /* font-size: 1.5em; */
}

.absolute-error-outer-container
  > .absolute-error-inner-container
  > button.exit-icon
  > span:last-child {
  margin: 0 0 0 0.3em;
  /* font-size: 1.5em; */
}

.absolute-error-outer-container
  > .absolute-error-inner-container
  > div.absolute-error-content-container {
  padding: 0 0.5em;
  margin: 0 0.5em;
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
}

.absolute-error-outer-container
  > .absolute-error-inner-container
  > div.absolute-error-content-container
  > p.absolute-error-title {
  font-size: 1.1em;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: bold;
}

.notification-error-transition-enter {
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s cubic-bezier(0.31, 0.92, 0.76, 1);
  transition-delay: 0.2s;
}

.notification-error-transition-enter.notification-error-transition-enter-active {
  opacity: 1;
  visibility: visible;
}

.notification-error-transition-leave {
  opacity: 1;
  visibility: visible;
  transition: all 0.2s cubic-bezier(0.31, 0.92, 0.76, 1);
}

.notification-error-transition-leave.notification-error-transition-leave-active {
  opacity: 0;
  visibility: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* DEALER */

.dealerDetails {
  font-style: italic;
  font-weight: bold;
}

.geotrue {
  color: green;
}

.geofalse {
  color: red;
}

.geop {
  /* display: none;
  visibility: hidden;*/
}

.table-button {
  background-color: transparent;
}
