.Dropzone {
  width: 100%;
  background-color: #fff;
  /*  border: 2px dashed rgb(187, 186, 186);
  border-radius: 50%;*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  padding: 1rem;
}

.Dropzone h4 {
  margin-bottom: 3rem;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none !important;
}

.fileimport-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}
